import React from "react"
import LayoutChild from "../components/layoutChild"
import SEO from "../components/seo"
import { color, fontSize, space } from '../components/constants'
import styled from 'styled-components'
import { graphql } from "gatsby"
import Img from "gatsby-image";


const PageLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    margin: 0px 36px 72px;

    @media (max-width: 1024px){
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }

    @media (max-width: 640px){
        grid-template-columns: 100% ;
        ${'' /* grid-template-rows: 100%; */}
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }
`
/* Text Group */
const CellTitleGroup = styled.div`
    width: 75%;
    @media (max-width: 640px){
        ${'' /* grid-row: 2; */}
        width: 75%;
        margin-top: 8px;
    }

`
const Title = styled.div`
    color: white;
    font-size: ${fontSize[3]};
    font-weight: 200;
    margin-top: ${space[4]+ "px"};
    line-height: 0.8;
    word-wrap:normal; /* YESSSSS */
    text-rendering: optimizeLegibility;

    @media (max-width: 1024px){
        font-size: ${fontSize[2]};
    }

    @media (max-width: 640px){
        word-spacing: normal;
        font-size: ${fontSize[2]};
    }

`
const Text = styled.p`
    margin-top: ${space[2]+ "px"};
    word-wrap:normal; /* YESSSSS */
    color: ${color.gray2};
    @media (max-width: 1024px){
        font-size: 16px;
    }
`
const Subhead = styled.p`
    margin-top: ${space[4]+ "px"};
    word-wrap:normal; /* YESSSSS */
    color: white;
    margin-bottom: 0px;
    @media (max-width: 1024px){
        font-size: 16px;
    }
`
/* Image styles */

const ImgSingle = styled(Img)`
    border-radius: 24px;
`
const ImgDouble = styled(Img)`
    border-radius: 24px;
    grid-column: 1 / span 2;
    @media (max-width: 640px){
        grid-column: 1 / span 1;
    }
`

export default ({ data }) => (
  <LayoutChild>
    <SEO 
      title="Simplifi Connect"
      description="A router setup and IoT contol application"
      image="../images/card.jpg"
    />
    <PageLayout>
        <CellTitleGroup>
            <Title >Simplifi&nbsp;Connect</Title>
            <Subhead>ROLE: UX DESIGN & PRODUCT STRATEGY</Subhead>
            <Text>Simplifi is a wireless, cellular router that makes high speed broadband totally portable. Simplifi needed an app to help consumers set up their devices and remotely monitor their network. Based on their existing brand, the app was designed to quickly set up their routers and enable remote&nbsp;features.</Text>
            <Subhead>Product strategy, concept, interaction design, design system, visual design, and&nbsp;prototyping.</Subhead>
            </CellTitleGroup>
            <ImgSingle 
                fluid={data.imageOne.childImageSharp.fluid}
                alt="Mobile phone with router status application showing"
                fadeIn={true}
                 />
            <ImgSingle 
                fluid={data.imageTwo.childImageSharp.fluid}
                alt="Mobile phone with router status application showing"
                fadeIn={true}
                 />
            <ImgSingle 
                fluid={data.imageThree.childImageSharp.fluid}
                alt="Hand holding mobile phone with router status application showing"
                fadeIn={true}
                 />
            <ImgDouble 
                fluid={data.imageFour.childImageSharp.fluid}
                alt="Multiple images of mobile phone interface"
                fadeIn={true}
                 />
            <ImgDouble 
                fluid={data.imageFive.childImageSharp.fluid}
                alt="Hand holding mobile phone with router status application showing"
                fadeIn={true}
                 />
            <ImgDouble 
                fluid={data.imageSix.childImageSharp.fluid}
                alt="Multiple images of mobile phone interface"
                fadeIn={true}
                 />
            <ImgDouble 
                fluid={data.imageSeven.childImageSharp.fluid}
                alt="Mobile phone with app store showing"
                fadeIn={true}
                 />
        </PageLayout>
  </LayoutChild>
)

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "simplifi/simplifi1.webp" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageTwo: file(relativePath: { eq: "simplifi/simplifi2.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    imageThree: file(relativePath: { eq: "simplifi/simplifi3.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    imageFour: file(relativePath: { eq: "simplifi/simplifi4.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    imageFive: file(relativePath: { eq: "simplifi/simplifi5.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSix: file(relativePath: { eq: "simplifi/simplifi6.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSeven: file(relativePath: { eq: "simplifi/simplifi7.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  }
`